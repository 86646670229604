@use '../var.module.scss';

.menuBox {
    transition: all 0.2s ease-in-out;
}

.leaflet-div-icon {
    border: none !important;
    background: none !important;
}
