@import './var.module.scss';

.logoImage {
  width: 80%;
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  // background-color: white;
}

.afa {
  svg {
    transition: all 0.2s ease-in-out;
  }

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  padding-left: 6px;
  padding-right: 6px;

  &::after {
    content: attr(data-code);
    min-width: 50px;
    text-align-last: center;
    border: 1px solid;
    background-color: white;
    // margin-top: 10px;
    margin-left: -46px;
    font-size: 12px;
    margin-top: 10px;
  }
}

.afa2 {
  svg {
    transition: all 0.2s ease-in-out;
  }

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  padding-left: 6px;
  padding-right: 6px;

  &::after {
    content: attr(data-code);
    min-width: 50px;
    text-align-last: center;
    border: 1px solid;
    background-color: var(--data-code-bg-color-var);
    // background-color: attr(data-color);
    // margin-top: 10px;
    margin-left: -46px;
    font-size: 12px;
    margin-top: 10px;
  }
}

.headerLogoImage {
  // position: fixed;
  width: 150px;
  top: 0;
  left: 0;
  width: inherit;
  padding: 10px 20px 
  // background-color: white;
}

.sideMenuHeader {
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
}

.navItemButton {
  height: 100%;
  width: 0;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  img {
    display: none;
  }
}

.navItem {
  .navDescription {
    transition: all 0.2s ease-in-out;
    padding-right: 1rem;
  }

  .navItemHeader {
    transition: all 0.2s ease-in-out;
  }

  &:hover[data-selected='true'] {
    .navItemButton {
      width: 50px;
    }

    .navDescription {
      transform: translateX(-55px);
    }

    .navItemHeader {
      transform: translateX(-45px);
    }

    img {
      display: block;
    }

    svg {
      width: 0;
    }
  }
}

.navItem {
  position: relative;

  .borderBottom {
    width: 0;
    transition: width 0.3s linear;
  }

  &:hover .borderBottom {
    width: 100%;
  }
}

.sideBar.mobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  background-color: $colorGrey7;
  overflow: hidden;

  .sideBarHeader {
    padding: 0.5rem 0;
  }

  .sideBarContent {
    overflow-x: hidden;
    background-color: white;

    &::-webkit-scrollbar {
      display: none,
    }
  }
}

.sideBarFooter {
  margin-top: auto;
  padding: 0.5rem;
  background-color: $colorGrey10;

  .userInfo {
    margin: 0.5rem;
    font-size: .9rem;
    display: flex;
    
    img {
      box-shadow: $shadow;
      background-color: white;
    }
  }
}