@import './var.module.scss';
.logWrapper {
    .logTitle {
        display: none;
    }
    .log {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 5px;
        &:not(:first-child) {
            margin-bottom: 10px;
        }
        &:hover {
            background-color: $colorGrey4;
        }
        > div {
            &:not(:last-child) {
                margin-right: 20px;
            }
            flex: 0 0 auto;
            font-size: $FontsizeXS;
        }
        .icon,
        .form,
        .formType {
            .image {
                width: 40px;
                height: 40px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .icon {
            .image {
                border-radius: 50%;
            }
        }
        .info {
            display: flex;
            flex: 0 1 auto;
            .content {
                margin-right: 20px;
                flex: 0 1 auto;
            }
            .dateTime {
                flex-grow: 1;
                flex-shrink: 0;
            }
        }
    }
}
@media only screen and (max-width: 992px) {
    .logWrapper {
        box-shadow: $shadow;
        border-radius: 8px;
        .logTitle {
            border-radius: 8px 8px 0 0;
            display: block;
            background-color: $colorGrey3;
            font-size: $FontsizeXS;
            padding: 4px 10px;
        }
        .log {
            border-radius: 0 0 8px 8px;
            background-color: $colorGrey10;
            > div {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
            .form {
                display: none;
            }
            .info {
                flex-direction: column;
                .content,
                .dateTime {
                    font-size: $Fontsize2XS;
                }
                .content {
                    padding-bottom: 4px;
                    margin-bottom: 4px;
                    border-bottom: 1px solid $colorGrey6;
                }
            }
        }
    }
}
