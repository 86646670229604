@import './var.module.scss';

.formItem {  
  box-shadow: $shadow,
}

.formItem {
  .formItemHeader {
    transition: all 0.2s ease-in-out;
  }

  // .formItemContent {
  //   background-image: linear-gradient(var(--data-code-bg-color-var),var(--data-code-bg-color-var));
  //   background-size: 100% 0%;
  //   background-repeat:no-repeat;
  //   background-position: top;
  //   transition: all 0.2s ease-in-out;
  // }

  // &:hover {
  //   .formItemContent {
  //     background-size: 100% 100%;
  //   }
  // }
  
  &:hover {
    .formItemContent {
      background-color: $colorGrey10;
    }
  }
}